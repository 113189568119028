import { useDisclosure } from '@chakra-ui/react'
import { useMachine } from '@xstate/react'
import _ from 'lodash'
import qs from 'qs'
import React from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useSearch } from 'src/components/search-v2/search-context'
import useMessages from 'src/hooks/messages/use-messages'
import { searchProperties } from 'src/requests/apartments'
import dateUtils from 'src/utils/date'

import { onlyEmpty, removeEmpty } from '../utils'
import mapMachine, {
  CardType,
  ContextType,
  events,
  FilterType,
  states,
} from './machine'

type ValidType = {
  [key in states]: boolean
}

type OnMoveFunc = ({
  radius,
  zoom,
  lat,
  lng,
}: {
  radius?: number
  zoom?: number
  lat?: number
  lng?: number
}) => void

type OnZoomType = (
  buildingId?: string,
  position?: { lat: number; lng: number }
) => void

type MapContextType = {
  send: any
  context: ContextType
  valid: ValidType
  state: states
  onMove: OnMoveFunc
  onZoom: OnMoveFunc
  onZoomPin: OnZoomType
  onStop: () => void
  onResetCoords: () => void
  onResetAllFilters: () => void
  onLoadMore: () => void
  isMapOpen: boolean
  onMapOpen: () => void
  onMapClose: () => void
  updateFilters: (filter: FilterType) => void
  getPropertyByBuildingId: (buildingId: string) => CardType | undefined
}

interface ProviderProps {
  children: React.ReactNode
}

const MapsContext = React.createContext({} as MapContextType)

export const useMaps = () => React.useContext(MapsContext)

const getGeosearch = async (params) => {
  const response = await searchProperties({ params })
  return response
}

const getRadiusByZoom = (zoom: number) => {
  switch (true) {
    case zoom <= 14:
      return 6000
    case zoom <= 15:
      return 4000
    case zoom <= 18:
      return 1000
    case zoom >= 20:
      return 60
    default:
      return 6000
  }
}

const MapsProvider = ({ children }: ProviderProps) => {
  const [, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = useSearch()
  const { emit, value } = useMessages('maps')
  const reloadSearch = useMessages('maps:reload')
  const {
    isOpen: isMapOpen,
    onOpen: onMapOpen,
    onClose: onMapClose,
  } = useDisclosure()

  const [content, send] = useMachine(mapMachine, {
    services: {
      request: async ({ activeCard, filters }) => {
        emit('footer', { data: true, category: 'load-more-search' })

        const response = await getGeosearch({
          ...filters,
          petFriendly:
            filters.petFriendly === 'whatever'
              ? undefined
              : filters.petFriendly,
          modalitie:
            filters.modalitie === 'ALL' ? undefined : filters.modalitie,
          mapZoom: filters?.zoom!,
          radius: activeCard ? 60 : getRadiusByZoom(filters.zoom!),
          checkOut: dateUtils.format(search.context?.end!, 'yyyy-MM-dd'),
          checkIn: dateUtils.format(search.context?.start!, 'yyyy-MM-dd'),
        })

        emit('footer', { data: false, category: 'load-more-search' })

        return response.data
      },
    },
  })

  const state = content.value as states

  const valid: ValidType = {
    zooming: content.matches(states.zooming),
    idle: content.matches(states.idle),
    error: content.matches(states.error),
    requesting: content.matches(states.requesting),
    moving: content.matches(states.moving),
  }

  const onMove: OnMoveFunc = React.useCallback(
    (event) => {
      if (valid.zooming) return

      emit('maps', { category: 'zoom-to-pin', data: false })

      send(events.move, {
        filters: { ...event, page: 1 },
        activeCard: undefined,
      })

      send(events.clearCards)

      send(events.request)
    },
    [valid, content.context.filters]
  )

  const onZoomPin: OnZoomType = React.useCallback(
    async (_, pos) => {
      window.scrollTo(0, 0)
      emit('maps', { category: 'zoom-to-pin', data: true })

      send(events.clearCards)

      send(events.zoom, {
        filters: {
          ...content.context.filters,
          lat: pos?.lat,
          lng: pos?.lng,
          radius: 60,
          zoom: 20,
          page: 1,
        },
        activeCard: `${pos?.lat}${pos?.lng}`,
      })

      return send(events.request)
    },
    [content.context.filters]
  )

  const onZoom = React.useCallback(
    async (event) => {
      if (value?.data) return

      send(events.clearCards)

      send(events.zoom, {
        filters: { ...content.context.filters, ...event, page: 1 },
      })

      send(events.stop)

      return send(events.request)
    },
    [value?.data, content.context.filters]
  )

  const onStop = React.useCallback(() => {
    // window.scrollTo(0, 0)
    send(events.stop)
  }, [])

  const onResetCoords = React.useCallback(() => {
    emit('maps', { category: 'zoom-to-pin', data: false })

    send(events.clearCards)

    send(events.stop)
    send(events.filter, {
      activeCard: undefined,
      filters: {
        ...content.context.filters,
        zoom: 14,
        radius: 6000,
        lat: Number(search.context.coords?.lat),
        lng: Number(search.context.coords?.long),
        page: 1,
      },
    })
    send(events.request)
  }, [
    search.context.coords?.lat,
    search.context.coords?.long,
    content.context.filters,
  ])

  const onLoadMore = React.useCallback(() => {
    if (content.context?.cards.length === 0) return
    if (content.context?.totalPages === content.context.filters?.page) return
    if (valid.requesting) return
    if (!valid.idle) return

    send(events.filter, {
      ...content.context,
      filters: {
        ...content.context.filters,
        page: content.context?.filters?.page! + 1,
      },
    })

    send(events.request)
  }, [content.context, content.context.filters])

  const getPropertyByBuildingId = React.useCallback(
    (buildingId?: string) => {
      if (!buildingId) {
        return content.context.cards.find((_, index) => index === 0)
      }

      return content.context.cards.find(
        (card) => card?.building?.aliasId === buildingId
      )
    },
    [content.context.cards]
  )

  const updateFilters = React.useCallback(
    (filter: FilterType) => {
      send(events.clearCards)
      emit('maps:reload', { category: 'reload-page', data: false })
      emit('maps', { category: 'zoom-to-pin', data: false })

      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

      const ctxFilters = _.omit(
        content.context.filters,
        Object.keys(removeEmpty(filter))
      )

      const queryClear = _.omit(
        queryParams as any,
        Object.keys(onlyEmpty(filter))
      )

      const rawFilters = removeEmpty({
        ...ctxFilters,
        ...filter,
      })

      const filters = _.omit(rawFilters, ['lat', 'lng', 'page', 'limit'])

      send(events.filter, {
        filters: {
          ...filters,
          page: 1,
          lat: content.context.filters.lat,
          lng: content.context.filters.lng,
        },
      })

      setSearchParams({ ...queryClear, ...filters })

      send(events.request)
    },
    [location.search, content.context]
  )

  const onResetAllFilters = React.useCallback(() => {
    send(events.clearCards)
    emit('maps:reload', { category: 'reload-page', data: false })
    emit('maps', { category: 'zoom-to-pin', data: false })

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

    const filtersContext = _.omit(
      { ...content.context.filters, ...queryParams },
      [
        'maxPrice',
        'minPrice',
        'guests',
        'modalitie',
        'amenities',
        'bedrooms',
        'bathrooms',
        'parkingSpaces',
        'petFriendly',
        'price',
        'sortingOrder',
        'sortingType',
      ]
    )

    send(events.clearFilter, {
      filters: filtersContext,
    })

    setSearchParams(filtersContext as any)

    send(events.request, { teste: 'jaum' })
  }, [location.search, content.context])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    send(events.filter, {
      ...content.context,
      filters: {
        ...content.context.filters,
        lat: Number(search.context.coords?.lat),
        lng: Number(search.context.coords?.long),
        zoom: Number(search.context.zoom),
        page: 1,
        radius: 6000,
      },
    })
    send(events.request)
  }, [])

  React.useEffect(() => {
    if (!reloadSearch.value?.data) return

    send(events.clearCards)

    send(events.filter, {
      filters: {
        lat: Number(search.context.coords?.lat),
        lng: Number(search.context.coords?.long),
        zoom: search.context.zoom,
        page: 1,
        radius: 6000,
      },
    })

    send(events.request)
  }, [
    location.search,
    search.context.coords?.lat,
    search.context.coords?.long,
    search.context.zoom,
    reloadSearch.value?.data,
  ])

  return (
    <MapsContext.Provider
      value={{
        send,
        state,
        valid,
        context: content.context,
        onMove,
        onStop,
        onLoadMore,
        onZoom,
        onZoomPin,
        onResetCoords,
        onResetAllFilters,
        getPropertyByBuildingId,
        onMapOpen,
        onMapClose,
        isMapOpen,
        updateFilters,
      }}
    >
      {children}
    </MapsContext.Provider>
  )
}

export default MapsProvider
