import { TFunction } from 'i18next'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'
import HousiSalesDetailsBuilder from 'src/pages/landing-pages/housi-sales-builder-details'
import { RedirectToExternal } from 'src/utils/redirect-external'

import { RequireSeo } from './custom'
import * as Components from './route-components'

const landingPagesRoutes = (t: TFunction): RouteObject[] => [
  {
    path: '/quem-somos',
    element: (
      <RequireSeo
        title={t('titles:whoWeAre.SEO_TITLE')}
        description={t('titles:whoWeAre.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.WhoWeAreComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/estimativa',
    element: (
      <RequireSeo
        title={t('titles:estimate.SEO_TITLE')}
        description={t('titles:estimate.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.EstimateComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/jp',
    element: (
      <RequireSeo
        title={t('titles:jp.SEO_TITLE')}
        description={t('titles:jp.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.JacquesPilonComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/fl',
    element: (
      <RequireSeo title="Housi Faria Lima">
        <Suspense fallback="">
          <Components.FariaLimaComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/covid',
    element: (
      <RequireSeo title="Covid">
        <Suspense fallback="">
          <Components.CovidComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/plus',
    element: (
      <RequireSeo title="Housi plus">
        <Suspense fallback="">
          <Components.PlusComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/partners',
    element: (
      <RequireSeo title="Housi Parceiros">
        <Suspense fallback="">
          <Components.PartnersListComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/partner/:id',
    element: (
      <RequireSeo title="Housi Parceiros">
        <Suspense fallback="">
          <Components.PartnerDetail />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/faq',
    element: (
      <RequireSeo
        title={t('titles:faq.SEO_TITLE')}
        description={t('titles:faq.SEO_DESCRIPTION')}
      >
        <Suspense fallback="">
          <Components.FaqComponent />
        </Suspense>
      </RequireSeo>
    ),
  },

  {
    path: '/housi-vendas',
    element: (
      <RequireSeo title="Housi vendas">
        <Suspense fallback="">
          <Components.HousiSalesComponent />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/housi-parceiros',
    element: (
      <RequireSeo title="Housi Parceiros">
        <Suspense fallback="">
          <Components.Marketplace />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/mood-2022',
    element: (
      <RequireSeo title="Housi Mood 2022">
        <Suspense fallback="">
          <Components.Mood2022 />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/incorporador',
    element: (
      <RequireSeo title="Housi Incorporador">
        <Suspense fallback="">
          <Components.Growth />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/decor',
    element: (
      <RequireSeo title="Housi Decor">
        <Suspense fallback="">
          <Components.Decor />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/vendas',
    element: <RedirectToExternal url="https://vendas.housi.com" />,
  },
  {
    path: '/vendas/detalhes/:enterprise',
    element: (
      <RequireSeo title={t('titles:sales.SEO_TITLE')}>
        <Suspense fallback="">
          <HousiSalesDetailsBuilder />
        </Suspense>
      </RequireSeo>
    ),
  },
  {
    path: '/appspace',
    element: (
      <RequireSeo title="Housi Appspace">
        <Suspense fallback="">
          <Components.Appspace />
        </Suspense>
      </RequireSeo>
    ),
  },

  {
    path: '/expertise-tech',
    element: (
      <RequireSeo title="Housi Expertise">
        <Suspense fallback="">
          <Components.ExpertiseTech />
        </Suspense>
      </RequireSeo>
    ),
  },
  // {
  //   path: '/datahousi',
  //   element: (
  //     <RequireSeo title="Data Housi">
  //       <Suspense fallback="">
  //         <Components.Data />
  //       </Suspense>
  //     </RequireSeo>
  //   ),
  // },
]

export default landingPagesRoutes
