import * as c from '@chakra-ui/react'
import { useLocation } from 'react-router'
import { useSearch } from 'src/components/search-v2/search-context'

import NavbarMobileItems from './items/components/navbar'
import MenuDrawer from './items/drawer'

const NavbarMobile = () => {
  const location = useLocation()
  const { valid } = useSearch()
  const { isOpen, onClose, onToggle } = c.useDisclosure()

  const [isMobile] = c.useMediaQuery(['(max-width: 768px)'])

  const isClosedNavbar = location.search.includes('navbar=false')

  if (!isMobile) return null
  if (valid.open) return null
  if (isClosedNavbar) return null

  return (
    <>
      <c.Flex
        width="100%"
        height="80px"
        bg="black.0"
        borderTopRadius="16px"
        position="sticky"
        bottom="0px"
        zIndex={999}
        padding="14px 46px 24px 46px"
        justifyContent="center"
        alignItems="center"
      >
        <NavbarMobileItems onToggle={onToggle} isOpen={isOpen} />
      </c.Flex>
      <MenuDrawer onClose={onClose} isOpen={isOpen} onToggle={onToggle} />
    </>
  )
}
export default NavbarMobile
